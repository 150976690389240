var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.getDataLoading)?_c('div',{staticClass:"floating-loading"},[_c('div',{staticClass:"d-flex",staticStyle:{"border":"1px solid #E8E9EB","width":"120px","padding":"10px","border-radius":"10px"}},[_c('a-spin',[_c('a-icon',{staticClass:"mr-3 mb-0",staticStyle:{"font-size":"20px"},attrs:{"slot":"indicator","type":"loading","spin":""},slot:"indicator"})],1),_vm._v(" Loading... ")],1)]):_vm._e(),_c('div',{staticClass:"card custom-card"},[_c('div',{staticClass:"card-body w-100"},[_c('div',{staticClass:"mb-4 h4 font-weight-bold text-color-green"},[_vm._v("Formulir Registrasi Kunjungan")]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Nama Lengkap")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'name',
                        {
                            initialValue: this.name,
                            rules: [
                            { required: true, message: 'Masukkan Nama Lengkap Pasien!' } ],
                        } ]),expression:"[\n                        'name',\n                        {\n                            initialValue: this.name,\n                            rules: [\n                            { required: true, message: 'Masukkan Nama Lengkap Pasien!' },\n                            ],\n                        },\n                        ]"}],attrs:{"disabled":"","size":"default","placeholder":"Masukkan nama lengkap"}})],1),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Tanggal Lahir")]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'birthdate',
                        {
                            initialValue: this.birthdate,
                            rules: [
                            { required: true, message: 'Masukkan tanggal lahir Pasien!' } ],
                        } ]),expression:"[\n                        'birthdate',\n                        {\n                            initialValue: this.birthdate,\n                            rules: [\n                            { required: true, message: 'Masukkan tanggal lahir Pasien!' },\n                            ],\n                        },\n                        ]"}],attrs:{"disabled":"","size":"default","placeholder":"Masukkan tanggal lahir"}})],1),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Jenis Kunjungan"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'visit_type',
                        {
                            initialValue: 'Rawat Jalan',
                            rules: [
                            { required: true, message: 'Masukkan Jenis Kunjungan Pasien!' } ],
                        } ]),expression:"[\n                        'visit_type',\n                        {\n                            initialValue: 'Rawat Jalan',\n                            rules: [\n                            { required: true, message: 'Masukkan Jenis Kunjungan Pasien!' },\n                            ],\n                        },\n                        ]"}],attrs:{"disabled":"","size":"default","placeholder":"Masukkan jenis kunjungan"}})],1),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Layanan"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'layanan',
                            {
                            initialValue: '',
                            rules: [
                                { required: true, message: 'Pilih layanan pasien!' } ],
                            } ]),expression:"[\n                            'layanan',\n                            {\n                            initialValue: '',\n                            rules: [\n                                { required: true, message: 'Pilih layanan pasien!' },\n                            ],\n                            },\n                        ]"}],attrs:{"size":"default","placeholder":"Pilih Layanan"},on:{"change":_vm.handleLayananChange}},[_c('a-select-option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Pilih tipe layanan")]),_vm._l((_vm.layananList),function(layanan){return _c('a-select-option',{key:layanan.id,attrs:{"value":layanan.id}},[_vm._v(" "+_vm._s(layanan.name)+" ")])})],2)],1),_c('a-form-item',[_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("Dokter"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'attending_doctor_id',
                            {
                            initialValue: '',
                            rules: [
                                { required: true, message: 'Pilih dokter!' } ],
                            } ]),expression:"[\n                            'attending_doctor_id',\n                            {\n                            initialValue: '',\n                            rules: [\n                                { required: true, message: 'Pilih dokter!' },\n                            ],\n                            },\n                        ]"}],attrs:{"size":"default","placeholder":"Pilih Dokter"}},[_c('a-select-option',{attrs:{"value":"","disabled":"","selected":""}},[_vm._v("Pilih dokter"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_vm._l((_vm.doctorList),function(doctor){return _c('a-select-option',{key:doctor.id,attrs:{"value":doctor.id}},[_vm._v(" "+_vm._s(doctor.User.name)+" ")])})],2)],1),_c('div',{staticClass:"h6 font-weight-bold"},[_vm._v("No Telepon Selular"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_vm._v(" Mohon konfirmasi nomor telepon selular terbaru ke pasien "),_c('div',{staticClass:"d-flex"},[_c('a-form-item',{staticClass:"col-xl-2"},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_no_code', { initialValue: this.phone_no_code, rules: [ { required: true, message: 'Kode negara harus dipilih' }],}]),expression:"['phone_no_code', { initialValue: this.phone_no_code, rules: [ { required: true, message: 'Kode negara harus dipilih' }],}]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"Kode Negara"}},_vm._l((_vm.phoneCode),function(code){return _c('a-select-option',{key:code.country,attrs:{"value":code.code}},[_vm._v(" "+_vm._s(code.code)+" ")])}),1)],1),_c('a-form-item',{staticClass:"col-xl-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'phone_no',
                            {
                                initialValue: this.phone_no,
                                rules: [
                                { required: true, message: 'Masukkan Nomor Telepon Pasien!' },
                                { pattern: /^\d{5,}$/, message: 'Nomor telepon tidak valid' } ],
                            } ]),expression:"[\n                            'phone_no',\n                            {\n                                initialValue: this.phone_no,\n                                rules: [\n                                { required: true, message: 'Masukkan Nomor Telepon Pasien!' },\n                                { pattern: /^\\d{5,}$/, message: 'Nomor telepon tidak valid' },\n                                ],\n                            },\n                            ]"}],attrs:{"size":"default","placeholder":"0000000"}})],1)],1),_c('a-button',{staticStyle:{"float":"right"},attrs:{"htmlType":"submit","size":"large","type":"primary","shape":"round","loading":this.isLoading}},[_c('img',{attrs:{"src":"resources/images/save-icon.svg","alt":"simpan","height":"25"}}),_c('strong',{staticClass:"h5"},[_vm._v(" Simpan")])])],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }